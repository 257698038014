import { PatientDocument } from '../../../database/documents/PatientDocument';
import { downloadFile, formatPhoneNumber, translateCause } from '../../../utils';
import React, { useContext, useEffect } from 'react';
import { AlertTableRow, TargetPatients } from './PatientsList';
import styled from 'styled-components';
import useNavigation from '../../../hooks/useNavigation';
import { CurrentUserContext } from '../../../context/CurrentUserContextProvider';
import { navigate } from '@reach/router';
import { Theme } from '../../../theme';
import dateFNSFormat from 'date-fns/format';
import { AlertType } from '../../../database/schemas/Patient';
import { useImmer } from 'use-immer';
import { FetchRequest } from '../../../types';
import LoadingSpinner from '../../../components/LoadingSpinner';
import StorageManager from '../../../StorageManager';
import IconManager, { IconType } from '../../../components/IconManager';

interface Props {
    alertTableRows: AlertTableRow[];
    patientFilter: TargetPatients;
    patientsWithAlerts: PatientDocument[];
}

interface State {
    alertCauses: FetchRequest<string[][]>;
}

export default function AlertDashboard(props: Props): JSX.Element {
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation();
    const [state, updateState] = useImmer<State>({ alertCauses: { fetching: true, data: [] } });

    useEffect(() => {
        (async () => {
            const alertCauses = await Promise.all(
                props.alertTableRows.map(tableRow => {
                    if (!tableRow.alerts.length) {
                        return [];
                    }
                    return Promise.all(
                        tableRow.alerts.map(async alert => {
                            if (alert.data.type === AlertType.medication) {
                                return translateCause(alert.data.cause, {
                                    medicationId: alert.data.details.medicationId,
                                    userId: alert.ref.parent.parent?.id ?? '',
                                });
                            } else {
                                return translateCause(alert.data.cause);
                            }
                        })
                    );
                })
            );
            updateState(draft => {
                draft.alertCauses.data = alertCauses;
                draft.alertCauses.fetching = false;
            });
        })();
    }, [props.alertTableRows]);

    return (
        <div className="w-full mt-5">
            <div className="flex flex-row justify-between">
                <h2 className="block text-3xl text-gray-900">Alert Dashboard</h2>
                <div
                    className="flex flex-row text-2xl"
                    style={{
                        alignItems: 'center',
                        marginLeft: '12px',
                        cursor: 'pointer',
                        color: '#2b6cb0',
                    }}
                    onClick={async () => {
                        const pdfUrl = await StorageManager.getAlertsFyiPdfURL();
                        await downloadFile(pdfUrl, 'symmetry_alerts_logic.pdf');
                    }}
                >
                    How to Use the Alert Dashboard{' '}
                    <div className="px-2">
                        <IconManager type={IconType.DOCUMENT_DOWNLOAD} stroke={'#2b6cb0'} className="mr-1" />
                    </div>
                </div>
            </div>
            <div className="overflow-auto border" style={{ height: '16.2rem' }}>
                <AlertTable className="w-full relative border-collapse">
                    <thead>
                        <tr>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">Name</th>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">Patient Contact</th>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">Date</th>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">Alert Details</th>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-center">Acknowledged</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.alertCauses.fetching ? (
                            <tr className="w-full absolute flex flex-row justify-center m-auto">
                                <LoadingSpinner type="page" className="mx-auto" />
                            </tr>
                        ) : (
                            props.alertTableRows.map((alertTableRow, index) => (
                                <tr
                                    className="w-full cursor-pointer"
                                    key={alertTableRow.patientId}
                                    onClick={() =>
                                        navigate(
                                            navigation.getPatientDetailsUrl(
                                                currentUser.claims!.currentOrgId!,
                                                alertTableRow.patientId
                                            )
                                        )
                                    }
                                >
                                    <td className="px-4 py-2 border-t border-b">
                                        {alertTableRow.patientName}
                                    </td>
                                    <td className="px-4 py-2 border-t border-b">
                                        {formatPhoneNumber(alertTableRow.patientPhoneNumber)}
                                    </td>
                                    <td className="px-4 py-2 border-t border-b">
                                        {dateFNSFormat(alertTableRow.createdAt.toDate(), 'MMMM do')}
                                    </td>
                                    <td className="px-4 py-2 border-t border-b">
                                        {!!state.alertCauses.data.length &&
                                            !!state.alertCauses.data[index] &&
                                            !!state.alertCauses.data[index].length &&
                                            state.alertCauses.data[index].map((cause, i) => (
                                                <span className="block" key={(index + 1) * (i + 1)}>
                                                    {cause}
                                                </span>
                                            ))}
                                    </td>
                                    <td className="px-4 py-2 border-t border-b text-center">
                                        <input
                                            type="checkbox"
                                            className={`form-checkbox text-${Theme.lightBlue} border-2 border-gray-400 hover:text-${Theme.lightBlueHover} text-2xl mx-auto cursor-pointer`}
                                            onClick={async e => {
                                                e.stopPropagation();
                                                await Promise.all(
                                                    alertTableRow.alerts.map(alert =>
                                                        alert.toggleAcknowledged()
                                                    )
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </AlertTable>
            </div>
        </div>
    );
}

const AlertTable = styled.table`
    thead > tr > th {
        background: #edf2f6;
        position: -webkit-sticky;
        position: sticky;
        top: -0.1%;
    }
`;
